const callback = (error: any) => {
	if (error) {
		// @ts-ignore
		window.Rollbar.error('Could not Send to Rollbar', error)
	}
}

const ZRollbar = {
	Init: () => {
		const rollbarConfig = {
			accessToken: '4a0b99a949734e6db3de323cc44a051b',
			captureUncaught: true,
			captureUnhandledRejections: false,
			payload: {
				environment: process.env.NODE_ENV,
				client: {
					javascript: {
						code_version: process.env.VERSION,
					},
				},
				source_map_enabled: true,
				guess_uncaught_frames: true,
				person: {
					// @ts-ignore
					id: window.comusUserId,
					// @ts-ignore
					username: window.comusUserName,
					// @ts-ignore
					email: window.comusUserEmail,
				},
			},
		}

		// eslint-disable-next-line global-require
		const Rollbar = require('rollbar/dist/rollbar.umd.min.js')
		Rollbar.init(rollbarConfig)
		// @ts-ignore
		window.Rollbar = Rollbar
	},
	ErrorMessage: (message: string, data: any = null) => {
		// @ts-ignore
		if (window.Rollbar) {
			if (data) {
				// @ts-ignore
				window.Rollbar.error(message, data, callback)
				return
			}

			// @ts-ignore
			window.Rollbar.error(message, callback)
		}
	},
	ErrorException: (e: any) => {
		// @ts-ignore
		if (window.Rollbar) {
			// @ts-ignore
			window.Rollbar.error(e, callback)
		}
	},
	Warning: (message: string) => {
		// @ts-ignore
		if (window.Rollbar) {
			// @ts-ignore
			window.Rollbar.error(message, callback)
		}
	},
}

export default ZRollbar
