import * as React from 'react'
import { Component } from 'react'
import * as PropTypes from 'prop-types'
import { Route, Link } from 'react-router-dom'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import Settings from './Menus/Settings'

type Props = {
	className?: string
	style?: React.CSSProperties
}

export default class extends React.Component<Props> {
	constructor(props: any, context: any) {
		super(props, context)
	}

	render() {
		const { className } = this.props
		return (
			<div className={className}>
				<Route path="/settings" render={({ match }) => <Settings />} />
			</div>
		)
	}
}
