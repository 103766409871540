import * as React from 'react'
import { AppContainer } from 'react-hot-loader'
import { ApolloProvider } from 'react-apollo'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import { BrowserRouter } from 'react-router-dom'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import MessageProvider from 'providers/MessageProvider'
// @ts-ignore - styled-components module has flow errors
import { ThemeProvider } from 'styled-components'

import CurrentUserProvider from '../providers/CurrentUserProvider'
import TranslationProvider from '../providers/TranslationProvider'
import AccountProvider from '../providers/AccountProvider'

import createApolloClient from '../init/createApolloClient'

const client = createApolloClient()

if (!window.currentLangugage) {
	window.currentLangugage = 'is_IS'
}

const muiTheme = getMuiTheme({
	palette: {
		primary1Color: '#f98000',
		pickerHeaderColor: '#f98000',
		primary2Color: '#f98000',
		accent1Color: '#f98000',
		accentColor: '#f98000',
		textColor: '#333',
	},
	raisedButton: {
		primaryColor: '#161d21',
	},
	dialog: {
		bodyColor: '#222222',
	},
	tabs: {
		backgroundColor: 'white',
		textColor: '#333333',
		selectedTextColor: '#f98000',
	},
	inkBar: {
		backgroundColor: '#f98000',
	},
})

const styledComponentsTheme = {
	breakpoints: [32, 48, 64, 80, 96],
}

const BaseApp = props => (
	<ApolloProvider client={client}>
		<MuiThemeProvider muiTheme={muiTheme}>
			<BrowserRouter>
				<ThemeProvider theme={styledComponentsTheme}>
					<TranslationProvider lang={window.currentLangugage}>
						<MessageProvider>
							<CurrentUserProvider>
								<AccountProvider>
									<AppContainer>{props.children}</AppContainer>
								</AccountProvider>
							</CurrentUserProvider>
						</MessageProvider>
					</TranslationProvider>
				</ThemeProvider>
			</BrowserRouter>
		</MuiThemeProvider>
	</ApolloProvider>
)

export default BaseApp
