import * as React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'
import * as PropTypes from 'prop-types'
import Utilities from 'Utilities'

const accountQuery = gql`
	query accountQuery {
		account {
			id
			title
			balance
			subscription
			hideExport
			hideSSN
			language
			language_Js
			pin
			imageId
			token
			flags {
				bookingSystem
			}
		}
	}
`

type Data = {
	loading: boolean
	account: { [string]: string | number }
}

type Props = {
	children: any
}

// @ts-ignore
@graphql(accountQuery)
class AccountProvider extends React.Component<Props> {
	static childContextTypes = {
		account: PropTypes.object,
	}

	getChildContext() {
		if (!this.props.data.loading) {
			//NOTE(ingimar): This is commented out so that you can open /Api/V2ea1 without logging in
			//TODO(ingimar): find a way to make exception for pages like /Api/V2ea1
			//if(this.props.data.account === null)
			//{
			//	Utilities.Redirect("/user");
			//}
			const account = {
				...this.props.data.account,
				// TODO(ingimar): Move this ClientSettings
				DateTimeFormat: 'YYYY-MM-DD HH:mm',
				DateFormat: 'YYYY-MM-DD',
			}
			Utilities.localstoragePersist('CurrentAccount', account)
			return {
				account: {
					loading: false,
					...account,
				},
			}
		}

		const account = Utilities.localstorageGet('CurrentAccount')
		if (account) {
			return {
				account: {
					loading: true,
					...account,
				},
			}
		}

		return {
			account: {
				loading: true,
				id: undefined,
				title: '',
				balance: 0,
				subscription: '',
				hideExport: true,
				hideSSN: true,
				language: 'en_GB',
				language_Js: 'en',
				pin: undefined,
				imageId: undefined,
				token: '',
				flags: undefined,
				// TODO(ingimar): Move this ClientSettings
				DateTimeFormat: 'YYYY-MM-DD HH:mm',
				DateFormat: 'YYYY-MM-DD',
			},
		}
	}

	render() {
		return this.props.children
	}
}

export default AccountProvider
