/* eslint import/no-absolute-path: 0*/
/* eslint camelcase: 0*/
import * as React from 'react'
import Polyglot from 'node-polyglot'
import * as PropTypes from 'prop-types'

// @ts-ignore This exists. I promise. It is in the webpack
import isLang from '/tmp/is.json'
// @ts-ignore This exists. I promise. It is in the webpack
import enLang from '/tmp/en.json'
// @ts-ignore This exists. I promise. It is in the webpack
import daLang from '/tmp/da.json' //Danish
// @ts-ignore This exists. I promise. It is in the webpack
import cs_CZ from '/tmp/cs_CZ.json'
import * as moment from 'moment'

const languages = {
	is_IS: isLang,
	en_US: enLang,
	da_DK: daLang,
	cs_CZ,
}

type Props = {
	children: any
	lang: string
}

class TranslationProvider extends React.Component<Props> {
	static childContextTypes = {
		translator: PropTypes.func,
	}
	getChildContext() {
		const polyglot = new Polyglot()
		polyglot.extend(languages[this.props.lang])

		moment.locale(this.props.lang)
		const translator = (arg1: string, arg2: any) => polyglot.t(arg1, arg2)

		return {
			translator,
		}
	}

	render() {
		return this.props.children
	}
}

export default TranslationProvider
