import fetch from 'isomorphic-fetch'
import queryString from 'query-string'
import * as React from 'react'

function linkState(me: any, property: string): Function {
	return event => {
		const newState = { [property]: event.target.value }
		me.setState(newState)
	}
}

function linkDatePickerState(me: any, property: string) {
	return (event: Event, value: any) => {
		const newState = { [property]: value }
		me.setState(newState)
	}
}

function linkSelectState(me: any, property: string) {
	return (event: Event, index: number, value: any) => {
		const newState = { [property]: value }
		me.setState(newState)
	}
}

const truncate = (inputString: string, length: number): string => {
	if (inputString.length > length) {
		return `${inputString.substring(0, length - 3)}...`
	}
	return inputString
}

const PriceFormat = (price: number, currencyOn: boolean = true, ending: string = ',-'): string => {
	let currency = ''
	if (false && currencyOn) {
		currency = ' kr'
	}
	// @ts-ignore: Suppressing the error
	return new Intl.NumberFormat('de-DE').format(price) + ending + currency
}

const NumberFormat = (number: number): string =>
	// @ts-ignore: Suppressing the error
	new Intl.NumberFormat('de-DE').format(number)
const timeStampToDateShortFormat = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' })

const timeStampToDateNumericFormat = (timestamp: number): string =>
	new Date(timestamp * 1000).toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' })

const timeStampToDateNumeric2Format = (timestamp: number): string => {
	const date = new Date(timestamp * 1000)

	let day = date.getDate()
	let month = date.getMonth() + 1
	let minutes = date.getMinutes()

	if (day.toString().length < 2) {
		// @ts-ignore
		day = `0${day}`
	}

	if (month.toString().length < 2) {
		// @ts-ignore
		month = `0${month}`
	}

	if (minutes.toString().length < 2) {
		// @ts-ignore
		minutes = `0${minutes}`
	}

	return `${day}/${month}/${date.getFullYear()} ${date.getHours()}:${minutes}`
}

// @ts-ignore
const Now = (): number => parseInt(new Date() / 1000, 10)

// eslint-disable-next-line no-unused-vars
const NextMidnight = (timestamp: number | null = null) => {
	// eslint-disable-next-line no-unused-vars
	let javascriptTimestamp = Date.now()
	if (timestamp) {
		// eslint-disable-next-line no-unused-vars
		javascriptTimestamp = timestamp * 1000
	}
	// @ts-ignore
	return parseInt(new Date().setHours(24, 0, 0, 0) / 1000, 10)
}

const PreviousMidnight = (timestamp: number | null = null) => {
	let javascriptTimestamp = Date.now()
	if (timestamp) {
		javascriptTimestamp = timestamp * 1000
	}
	// @ts-ignore
	return parseInt(new Date(javascriptTimestamp).setHours(0, 0, 0, 0) / 1000, 10)
}

const Rgb2Hex = (r: number, g: number, b: number) => `#${r.toString(16)}${g.toString(16)}${b.toString(16)}`

const hsvToRgb = (h: number, s: number, v: number) => {
	let r: number = 0,
		g: number = 0,
		b: number = 0

	const i = Math.floor(h * 6)
	const f = h * 6 - i
	const p = v * (1 - s)
	const q = v * (1 - f * s)
	const t = v * (1 - (1 - f) * s)

	switch (i % 6) {
		case 0:
			;(r = v), (g = t), (b = p)
			break
		case 1:
			;(r = q), (g = v), (b = p)
			break
		case 2:
			;(r = p), (g = v), (b = t)
			break
		case 3:
			;(r = p), (g = q), (b = v)
			break
		case 4:
			;(r = t), (g = p), (b = v)
			break
		case 5:
			;(r = v), (g = p), (b = q)
			break
	}

	return [Math.floor(r * 255), Math.floor(g * 255), Math.floor(b * 255)]
}

const hsvToRgb2 = (h: number, s: number, v: number) => hsvToRgb(h / 360, s / 100, v / 100)

const getColor = (index: number) => {
	//var hue = 40 * Math.abs(Number(index)) + 60;
	//let ble = hsvToRgb2(hue, 50, 90);
	const ble: Array<string> = []

	ble.push(Rgb2Hex(255, 0, 0))
	ble.push(Rgb2Hex(0, 255, 0))
	ble.push(Rgb2Hex(0, 0, 255))

	return ble[index]
}

const getBase64 = (file: File) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => {
			resolve(reader.result)
		}
		reader.onerror = error => {
			reject(error)
		}
	})
/**
 * Simple object check.
 * @param item
 * @return {boolean}
 */
const isObject = (item: any) => item && typeof item === 'object' && !Array.isArray(item)
/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
const mergeDeep = (target: any, ...sources: any) => {
	if (!sources.length) return target
	const source = sources.shift()
	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} })
				mergeDeep(target[key], source[key])
			} else {
				Object.assign(target, { [key]: source[key] })
			}
		}
	}
	return mergeDeep(target, ...sources)
}

const ObjectCompare = (obj1: any, obj2: any): boolean => {
	//Loop through properties in object 1
	for (const p in obj1) {
		//Check property exists on both objects
		if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false

		switch (typeof obj1[p]) {
			//Deep compare objects
			case 'object':
				if (!ObjectCompare(obj1[p], obj2[p])) return false
				break
			//Compare function code
			case 'function':
				if (typeof obj2[p] === 'undefined' || (p != 'compare' && obj1[p].toString() != obj2[p].toString()))
					return false
				break
			//Compare values
			default:
				if (obj1[p] != obj2[p]) return false
		}
	}

	//Check object 2 for any extra properties
	for (const p in obj2) {
		if (typeof obj1[p] === 'undefined') return false
	}
	return true
}

const urlLink = (url: string) => (e: any /*FIXME: KeyboardEvent*/) => {
	e.preventDefault()
	const mouseButtonLeft = 0
	const mouseButtonMiddle = 1
	const mouseButtonRight = 2

	if (e.button === mouseButtonRight || e.altKey || e.ctrlKey || e.metaKey || e.shiftKey) {
		return
	}

	if (e.button === mouseButtonLeft) {
		// @ts-ignore
		window.location = url
	} else if (e.button === mouseButtonMiddle) {
		window.open(url, '_blank')
	}
}

const highChartifyColor = (color: string) => ({
	radialGradient: { cx: 0.5, cy: 0.3, r: 0.7 },
	stops: [
		[0, color],
		// @ts-ignore: Suppressing the error
		[
			1,
			// @ts-ignore
			window.Highcharts.Color(color)
				.brighten(-0.3)
				.get('rgb'),
		], // darken
	],
})

const CreateGraphQlFetcher = (apiUri: string, graphQlToken: string) => {
	const token = graphQlToken
	return (graphQLParams: string) =>
		fetch(apiUri + token, {
			credentials: 'include',
			method: 'post',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(graphQLParams),
		}).then(response => response.json())
}

const CreateWrappedGraphQLFetcher = (fetchingFunction: Function) => {
	const localFunction = fetchingFunction
	return (query: any) => localFunction({ query })
}

const getCookie = (cname: string): string => {
	const name = `${cname}=`
	const decodedCookie = decodeURIComponent(document.cookie)
	const ca = decodedCookie.split(';')
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) == ' ') {
			c = c.substring(1)
		}

		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

const UppercaseFirst = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1)

const ObjectToArray = (obj: any): Array<any> => Object.keys(obj).map(key => obj[key])

const propPasser = (WrappedComponent, extraProps) =>
	class propPasser extends React.Component {
		render() {
			return <WrappedComponent {...extraProps} {...this.props} />
		}
	}

const range = (start: number, end: number): Array<number> => {
	const buffer = []
	for (let i = start; i <= end; i++) {
		buffer.push(i)
	}
	return buffer
}

const clone = (obj: any): any => {
	let copy

	// Handle the 3 simple types, and null or undefined
	if (obj == null || typeof obj !== 'object') return obj

	// Handle Date
	if (obj instanceof Date) {
		copy = new Date()
		copy.setTime(obj.getTime())
		return copy
	}

	// Handle Array
	if (obj instanceof Array) {
		copy = []
		for (let i = 0, len = obj.length; i < len; i++) {
			copy[i] = clone(obj[i])
		}
		return copy
	}

	// Handle Object
	if (obj instanceof Object) {
		copy = {}
		for (const attr in obj) {
			if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr])
		}
		return copy
	}

	throw new Error("Unable to copy obj! Its type isn't supported.")
}

function localstoragePersist(key: string, value: any): void {
	if (localStorage) {
		localStorage.setItem(key, JSON.stringify(value))
	}
}

function localstorageGet(key: string): any {
	if (localStorage) {
		const items = localStorage.getItem(key)
		if (items) {
			if (typeof items === 'string') {
				return JSON.parse(items)
			}
			return items
		}
	}
	return undefined
}

function localstorageRemove(key: string): void {
	if (localStorage) {
		localStorage.removeItem(key)
	}
}

const objGet = (obj: any, props, defaultValue) =>
	props.split('.').reduce((acc, p) => {
		if (acc === null || acc === undefined || acc[p] === undefined) {
			return defaultValue
		}
		return acc[p]
	}, obj)

const objSet = (obj: any, is, value) => {
	if (typeof is === 'string') {
		return objSet(obj, is.split('.'), value)
	} else if (is.length == 1 && value !== undefined) {
		return (obj[is[0]] = value)
	} else if (is.length == 0) {
		return obj
	}

	return objSet(obj[is[0]], is.slice(1), value)
}

function Redirect(url: string): void {
	//window.location = url;
	window.location.replace(url)
}

function RefreshPage(): void {
	window.location.reload()
}

function isNumeric(n): boolean {
	return !isNaN(parseFloat(n)) && isFinite(n)
}

function get(string: string, defaultValue: any = null) {
	const q = queryString.parse(window.location.search)

	if (string in q) {
		return q[string]
	}

	return defaultValue
}

function get_string(string: string, defaultValue: string = ''): string {
	return String(get(string, defaultValue))
}

function get_has(string: string): boolean {
	const q = queryString.parse(window.location.search)
	return string in q
}

export default {
	Input: {
		get,
		get_string,
		get_has,
	},
	linker: {
		linkState,
		linkSelectState,
		linkDatePickerState,
	},
	linkState,
	linkSelectState,
	linkDatePickerState,

	NumberFormat,
	truncate,
	PriceFormat,
	range,
	timeStampToDateShortFormat,
	timeStampToDateNumericFormat,
	timeStampToDateNumeric2Format,
	getColor,
	UppercaseFirst,
	Timestamp: {
		Now,
		NextMidnight,
		PreviousMidnight,
	},
	getBase64,
	urlLink,
	highChartifyColor,
	CreateGraphQlFetcher,
	CreateWrappedGraphQLFetcher,
	getCookie,
	propPasser,

	isNumeric,
	hsvToRgb2,

	//LocalStorage
	localstoragePersist,
	localstorageGet,
	localstorageRemove,

	//Object functions
	ObjectToArray,
	isObject,
	mergeDeep,
	clone,
	ObjectCompare,
	objGet,
	objSet,

	//Page Navigation functions
	Redirect,
	RefreshPage,
}
