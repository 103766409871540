/* eslint import/first: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint import/no-unresolved: 0 */
import * as React from 'react'
import { render } from 'react-dom'
import App from './components/App'
import LeftMenu from './components/LeftMenu'
import Rollbar from 'ZRollbar'
import BaseApp from './init/BaseApp'

if (process.env.NODE_ENV === 'production') {
	Rollbar.Init()
}

require('./Old/zenter.tsx')

//Importing the css
import 'bootstrap/less/bootstrap.less'
import '../../../../public/Comus/inc/css/bootstrap-xlgrid.css'

import 'ionicons/scss/ionicons.scss'
import 'font-awesome/scss/font-awesome.scss'
import '../../../../public/Comus/inc/css/colpick.css'
import '../../../../public/Comus/inc/css/ui-lightness/jquery-ui-1.10.4.custom.css'
import '../../../../public/Comus/inc/css/jquery.lightbox-0.5.css'

// @ts-ignore
import './css/main.scss'

window.onload = () => {
	const rootElement = document.getElementById('root')

	const Root = () => (
		<BaseApp>
			<App />
		</BaseApp>
	)

	if (rootElement) {
		render(<Root />, rootElement)
	}

	const LeftMenuItem = () => (
		<BaseApp>
			<LeftMenu />
		</BaseApp>
	)

	const leftMenuElement = document.getElementById('LeftMenu')
	if (leftMenuElement !== null) {
		render(<LeftMenuItem />, leftMenuElement)
	}
}

console && console.log && console.log('Zenter system ' + process.env.VERSION)
